import React, { ReactElement, useEffect } from 'react'
import { navigate } from 'gatsby'
import PageLayout from '../components/components/page-layout'

/**
 * Purpose of this page is just to redirect to
 * the `/en/` route. This will be handled by Netlify
 * however, we can also add a useEffect here to make
 * sure we navigate away
 *
 * @returns {ReactElement}
 */
const IndexPage = (): ReactElement => {
  useEffect(() => {
    navigate(`/en/`)
  }, [])

  return (
    <PageLayout theme="dark" className="c__home-page">
      <></>
    </PageLayout>
  )
}

export default IndexPage
